/*
 * PAGE FOOTER
 * As this may be used standalone, no common css classes are used!
 */

// #uwa-footer__row-back
// #uwa-footer__row-primary
// #uwa-footer__row-secondary
// #uwa-footer__row-tertiary

// body[data-navigation="future-students-navigation"] .site-wrapper
//     position: initial

$nav-font-size: 15px


[class*=uwa-nav], [id^=uwa-footer]
    font-weight: normal
    font-style: normal
    line-height: 1.5
    button, a, img, ul, li, h1, h2, h3, p, button, input, address
        font-family: $type-sans
        font-size: 13px
        @include reset()
    ul
        list-style: none
    img
        vertical-align: bottom


// Standard row layout
[id^=uwa-footer__row]
    background-color: $col-blue
    color: white
    font-size: 13px
    h1
        color: white
    > *
        @include page-content-block()
        padding-bottom: $margin-block

    a
        @include link(1, 0, $col-white)

    strong
        font-weight: 600

    details
        summary
            // padding: size(1) 0
            list-style: none
            line-height: size(3)
            &::-webkit-details-marker
                display: none
            &:hover, &:focus
                text-decoration: underline
            &:after
                @include psuedo-content-img('../img/menu-chevron_white.svg')
                background-size: 14px
                display: inline-block
                content: ''
                vertical-align: bottom
                width: size(3)
                height: size(3)
                transform: rotate(0deg)
                transition: transform 200ms ease
        &[open]
            background-color: rgba($col-white, 0.05)
            summary:after
                transform: rotate(180deg)


#uwa-footer__row-back
    background-color: transparent
    margin-top: $margin-section
    > *
        display: flex
        flex-direction: row nowrap
        justify-content: flex-end
        &:has(a + a)
            justify-content: space-between
    a
        @include link(1, 0, $col-link)
        display: inline-block
        font-size: 15px
    a._top
        @include icon-background('../img/arrow-up.svg', size(2.5), size(1), $side: right)
    a._home
        @include icon-background('../img/arrow-left.svg', size(2.5), size(1), $side: left)

#uwa-footer__row-primary, #uwa-footer__row-secondary, #uwa-footer__row-tertiary
    color: $col-white
    a
        color: $col-white !important

#uwa-footer__row-primary

    padding-top: $margin-text
    h1
        // @include overline()
        padding-top: $margin-block
        margin-bottom: $margin-block
        a
            text-transform: uppercase
            font-weight: 700
            @include link(0, 1, $col-white)

    .uwa-footer__info
        @include grid-parent()

        p:not(:last-child)
            margin-bottom: $margin-text * 0.5

        // UWA Contact details
        address, ._address
            @include grid-item(12, 6, 4)
            strong
                font-size: 17px
            a
                @include link(0, 1)
        // Links
        nav, ._links
            @include grid-item(12, 6, 3)
            ul
                // flexible columns allow better distribution
                display: grid
                grid-template-columns: auto auto auto
                gap: size(1)
        // Indigenous committment
        aside, ._indigenous
            @include grid-item(12, 12, 5)
            background-image: url('../img/footer/indigenous_light.png')
            background-position: size(1) 0
            background-repeat: no-repeat
            background-size: 300px auto
            p:first-child
                font-family: $type-serif
                font-size: 14px
                font-weight: 600
                line-height: 20px

        details
            padding: size(1)
            margin-left: size(-1)
            display: inline-block
            &:not(:first-child)
                margin-top: size(4)
            ._flag-australia, ._flag-china
                padding-left: size(5)
                background-position: left center
                background-repeat: no-repeat
                background-size: size(4) auto
                line-height: size(3)
            ._flag-australia
                background-image: url('../img/flag-australia.png')
            ._flag-china
                background-image: url('../img/flag-china.png')
            p
                margin-top: size(1)
                a
                    display: block
                    @include link(0, 1)





#uwa-footer__row-secondary
    .uwa-footer__network
        // display: flex
        // flex-flow: row nowrap
        padding: size(2) 0
        border-width: 1px 0 1px 0
        border-color: $col-white
        border-style: solid
        @include grid-parent()

        > *
            @include grid-item(12, 6)
            // padding: 0
        ul
            display: flex
            align-items: center
            gap: $margin-block
        img
            height: 50px

        a[class^=_icon]
            line-height: 200px
            overflow: hidden
            width: size(5)
            height: size(5)
            display: block
            text-decoration: none
            background-size: size(4)
            background-repeat: no-repeat
            background-position: center
            &:hover
                background-color: $col-white
        ._icon-facebook
            // recoloured versions don't retain folder
            background-image: url('../img/social-facebook_white.svg')
            &:hover, &:focus
                background-image: url('../img/social-facebook_blue.svg')
        ._icon-instagram
            background-image: url('../img/social-instagram_white.svg')
            &:hover, &:focus
                background-image: url('../img/social-instagram_blue.svg')
        ._icon-x
            background-image: url('../img/social-x_white.svg')
            &:hover, &:focus
                background-image: url('../img/social-x_blue.svg')
        ._icon-youtube
            background-image: url('../img/social-youtube_white.svg')
            &:hover, &:focus
                background-image: url('../img/social-youtube_blue.svg')
        ._icon-linkedin
            background-image: url('../img/social-linkedin_white.svg')
            &:hover, &:focus
                background-image: url('../img/social-linkedin_blue.svg')
        ._icon-tiktok
            background-image: url('../img/social-tiktok_white.svg')
            &:hover, &:focus
                background-image: url('../img/social-tiktok_blue.svg')


#uwa-footer__row-tertiary
    details[open]
        summary
            margin-bottom: size(1)
