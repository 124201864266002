/* UWA Header layout and global styles for SXA and General tempaltes */

// Timothy Phillips
@use "sass:math"

$nav-padding: size(1)
$nav-link-height: 30px
$nav-link-pad: 15px
$nav-gap: size(3)
$nav-primary-height: 60px
$logo-height: 48px
$nav-font-size: 15px
// Padding is half the shield width
$logo-pad: math.ceil($logo-height * 0.3333)

// Two buttons, Two logos, standard space between, margins and gap
$lockup-breakpoint: $logo-height * 2 + 160 * 2 + size(7)

// CSS reset to counter any existing frameworks
[class*=uwa-nav], [id^=uwa-header]
    font-weight: normal
    font-style: normal
    line-height: 1.5
    button, a, img, ul, ol, li, h1, h2, h3, p, button, input
        font-family: $type-sans
        @include reset()
    ul, li
        list-style: none
    menu
        margin-top: 0
        margin-bottom: 0
    img
        vertical-align: bottom


header
    // relative z-index to keep below menus and above backdrop
    z-index: $core-zindex-navigation
    position: relative



// Standard row layout
[id^=uwa-header__row]
    width: 100% !important
    padding: 0px
    margin: 0px
    > *

        @include page-content-block()
        @include breakpoint('medium')
            align-items: flex-start
            column-gap: $nav-gap

    // Counter some foundation insanity used in SXA
    .grid-padding-x > .cell
        padding: 0
        flex-shrink: 0
        width: auto
        > *
            margin: 0
    .grid-padding-x .grid-padding-x
        margin: 0


// Common for the uwa-blue parts
#uwa-header__row-primary, #uwa-header__row-single, #uwa-header__row-secondary
    background-color: $col-blue
    color: $col-white
    > *
        display: flex
        flex-flow: row wrap
        align-items: center
        column-gap: $nav-gap
        > *:first-child
            padding-right: $nav-gap


// Top row layout (The only row on UWA home page)
#uwa-header__row-primary, #uwa-header__row-single
    > *
        flex-flow: row nowrap
        > *
            flex-shrink: 0
            flex-grow: 0
            width: auto

    #uwa-header__container-logo
        flex-grow: 1
        flex-shrink: 0
        a + a
            @include breakpoint-max($lockup-breakpoint)
                display: none

    // Menu button is for mobile only
    #uwa-header__container-menu
        display: none

    @include breakpoint-max('small')
        // Hide most links for mobile
        > *
            column-gap: 0
        > * > *
            display: none
        // Show only these!
        #uwa-header__container-logo,
        #uwa-header__container-menu,
        #uwa-header__container-search
            display: block
        #uwa-header__container-logo
            flex-grow: 1


#uwa-header__container-logo
    padding-right: 0 !important
    .uwa-nav__logo
        // But not including the Seek wisdom
        // padding-bottom: $logo-pad -2px
        a:first-child
            // Align the left of the crest with content
            margin-left: 0 - $nav-primary-height * 0.09


// The All UWA menu gets a little more oomph
#uwa-header__container-global button
    font-weight: 500


#uwa-header__row-secondary
    // There's a lot of blue space, which we're reserving for overflow expand
    // So we'll pull the site title/nav row up to fill it
    > *
        flex-flow: row nowrap
        > *
            margin-top: 2 - $logo-pad

    #uwa-header__container-title
        max-width: 100%
        h1
            display: block
            margin:  0
            color: white
            font-family: $type-sans
            flex-grow: 1
            margin: 0
            font-size: $nav-font-size + 6
            font-weight: 500
            line-height: $nav-link-height
            padding: $nav-link-pad 0
            a
                @include link(0, 1, $col-white)
        @include breakpoint('medium')
            h1
                // Nudge up to match the link position
                padding: $nav-link-pad - 2px 0 $nav-link-pad + 2px 0
                white-space: nowrap
        @include breakpoint-max('small')
            flex-grow: 1
            padding-right: 0


// Body class to hide site title when there's a readable logo
@include breakpoint($lockup-breakpoint)
    .uwa-header__logo-as-title #uwa-header__row-secondary #uwa-header__container-title,
    #uwa-header__container-title._lockup_as_title
        display: none


// Site nav with overflow can always grow
#uwa-header__container-site
    flex-grow: 1
    flex-shrink: 1
    overflow-x: hidden
    @include breakpoint-max('small')
        display: none


#uwa-header__row-tertiary
    // background-color: $col-white
    // Default spacing after breadcrumbs
    h1, h2
        margin-top: $margin-block
        margin-bottom: $margin-block * 2
        font-family: $type-sans
        @include head($type-h1, 0)
        color: $col-blue

