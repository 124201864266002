// This is imported FIRST
@use "sass:math"

// CSS Vars
@mixin define-css-vars($scope: ':root')
    #{$scope}
        // Intermediate sizes -- sass index start a 1 >={
        @for $i from 1 through 6
            // @mixin breakpoint isn't declared yet
            @media screen and (min-width: #{nth($breakpoint-sizes, $i)})
                --bmr-page-margin: #{nth($breakpoint-page-margin, $i)}
                --bmr-grid-padding: #{math.div(nth($breakpoint-grid-gutter, $i), 2)}
                --bmr-grid-offset: #{0 - math.div(nth($breakpoint-grid-gutter, $i), 2)}


// Strip the px, rem, etc from a value with no conversion.
@function strip-unit($value)
    // something about division in sass strips the unit
    @return math.div($value, ($value * 0 + 1))


// Calc function for fluid values.
@function fluidcalc-unclamped($min-value, $max-value, $min-vw: $fluid-min-vw, $max-vw: $fluid-max-vw)
    // NOT INTENDED FOR GENERAL USE -- Use fluid Mixins instead.
    // Must be used with media queries for min/max clamping
    @return calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}))


// padding, margin (and more) sizes are multiples of a root size.
@function size($multiplier)
    @if $multiplier == 0
        @return 0.5 * $size-root
    @else
        @return $multiplier * $size-root
