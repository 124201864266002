
@use "sass:math"

$nav-primary-height: 60px
$nav-link-height: 30px // Nav is twice this + padding
$logo-height: 48px
// Padding is half the shield width
$logo-pad: math.ceil($logo-height * 0.3333)
$nav-link-pad: 15px
$nav-padding: size(1)
$nav-image-height: 30px
$nav-line-height: $nav-link-height - size(1)
$nav-gap: size(3)

$nav-color-bright: #00A3E1
$nav-color-dark: #002855
$nav-font-size: 15px

// Deprecated
$nav-content-max-width: 1200px

// [class*=uwa-nav] //, [id^=uwa-header]
//     button, a, img, ul, li, h1, h2, h3, p, button, input
//         font-family: $type-sans
//         @include reset()
//     ul
//         list-style: none
//     img
//         vertical-align: bottom
//     button
//         cursor: pointer

// @font-face
//     font-family: UWA
//     src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/uwa/uwa-slab-regular.eot')
//     src: url('https://static.weboffice.uwa.edu.au/visualid/fonts/uwa/uwa-slab-regular.eot?#iefix') format("embedded-opentype"), url('https://static.weboffice.uwa.edu.au/visualid/fonts/uwa/uwa-slab-regular.otf') format("opentype"), url('https://static.weboffice.uwa.edu.au/visualid/fonts/uwa/uwa-slab-regular.woff') format("woff")
//     font-style: normal


.uwa-nav__logo
    // min-width: 160px
    white-space: nowrap
    display: flex
    flex-flow: row nowrap
    // Spacing must be half the crest width
    padding-top: $logo-pad
    padding-bottom: $logo-pad

    a, img, svg, text
        font-family: UWA
    a
        display: block

    img, svg
        max-height: $logo-height
        height: $logo-height
        vertical-align: middle

    a + a
        position: relative
        padding-left: size(2)
        margin-left: size(2)
        // padding-bottom: 2px
        &:before
            display: block
            position: absolute
            content: ''
            left: -1px
            top: 0px
            width: 2px
            bottom: 2px
            background-color: $col-white
        img, svg
            // Align with logo, not ribbon
            height: $logo-height - 2
            max-height: $logo-height - 2


    svg text
        font-family: UWA, "UWA Regular", "UWA-regular"
        fill: $col-white



.uwa-nav__links

    // Common shared styling for buttons and link
    button, a[href]
        position: relative
        outline: none
        background: none
        display: block
        padding: 0
        font-weight: 400 !important
        font-size: $nav-font-size !important
        // Disabled line-height - inconsitencies between link and button
        // allow for border, and raising the text 2px so it centers better
        // line-height: $nav-link-height - 2 !important
        padding-bottom: 2px !important
        // Override some default styles in SXA
        border-width: 0px !important
        // Important to override old SXA CSS.
        border-bottom: 1px solid transparent !important
        white-space: nowrap
        text-decoration: none
        color: $col-white
        // @include link(0, 1, $col-white)

    button._menu, a[href]
        &:before
            // Underline as a psuedo-object, can be animated and doesn't affect layout
            position: absolute
            background-color: $nav-color-bright
            content: ''
            left: 50%
            right: 50%
            // Width is included to prevent rounding erros making a visible center dot
            width: 0%
            // default size is for links
            bottom: 0px
            height: 2px
            transition-property: left, right, width
            transition-duration: 200ms
            transition-timing-function: ease
        &:hover, &:focus-visible, &.__open
            &:before
                left: 0
                right: 0
                width: 100%

    // a[href]._icon-user
    //     padding-left: size(4)
    //     background-image: url('../img/user_white.svg')
    //     background-position: center left
    //     background-size: size(3.5) auto
    //     background-repeat: no-repeat


    button._menu
        text-decoration: none !important
        cursor: progress !important // Show hourglass pointer if not initialised
        @include psuedo-object('after', size(2), size(1))
            @include psuedo-content-img('../img/menu-chevron_bright.svg')
            height: $nav-link-height
            transform: rotate(0deg)
            transition: transform 200ms ease
        &.__open
            &:after
                transform: rotate(180deg)

        &:before
            // Don't show underline if javascript hasn't activated yet
            height: 0px

        &.__init
            cursor: auto !important
            &:before
                height: 2px

        &:not([data-target])
            + ul, + div
                // Probably content
                display: none

    // Search and mobile menu
    button._icon
        background-repeat: no-repeat
        border: 0px solid transparent !important
        text-decoration: none
        background-position: center
        background-size: size(4)
        width: $logo-height
        height: $logo-height
        // Text is pushed out of view for screen readers
        // overflow-x: hidden
        // padding-left: 100%
        font-size: 0px !important
        position: relative
        &:before, &:after
            display: block
            position: absolute
            content: ''
        &.__open
            // It's a toggle, so we only an icon swap is all that's needed
            background-image: url('../img/close_white.svg') !important
            // animation: uwa-nav_background-slide-right 100ms linear
        &.__back
            // For mobile menu button, added when sub menu open
            background-image: url('../img/chevron-left_white.svg') !important
            // animation: uwa-nav_background-slide-left 100ms linear
        &.__open, &.__back
            &:before
                // A joining block to the menu
                top: 100%
                height: $logo-pad
                left: 0px
                right: 0px

        &:hover, &:focus-visible
            // border-color: $col-white !important
            &:after
                // Outline that doesn't misalign when zoomed
                top: 0
                left: 0
                width: 100%
                height: 100%
                border: 1px solid $col-white
        @media (hover:none)
            // Remove the border for touch
            &:after
                display: none


    button#uwa-nav__mobile
        background-image: url('../img/menu_white.svg')
        background-color: $nav-color-dark
        &:before
            background-color: $nav-color-dark
        @include breakpoint('medium')
            // Only needed when nav and cta links are hidden
            display: none

    button#uwa-nav__search
        background-image: url('../img/search_white.svg')
        background-color: $nav-color-bright
        &:before
            background-color: $nav-color-bright


// @keyframes uwa-nav_background-slide-left
//     0%
//         background-position: 100% center
//     100%
//         background-position: center center

// @keyframes uwa-nav_background-slide-right
//     0%
//         background-position: 0% center
//     100%
//         background-position: center center

// Main navigation with overflow truncation
// This is fine tuned to limit layout shift before JS kicks in

#uwa-nav__site
    position: relative
    height: $nav-link-height * 2
    align-content: center
    overflow-x: hidden
    width: 100%

    @include breakpoint-max('small')
        // Hide on small screens, mobile button will be shown
        display: none !important

    ul
        position: relative
        display: flex
        flex-flow: row wrap
        justify-content: flex-end
        align-items: center
        white-space: nowrap
        column-gap: $nav-gap
        row-gap: 0
        // Out of the box, anything that doesn't fit will wrap and be hidden
        // to limit layout shift.
        overflow: hidden
        height: $nav-link-height
        box-sizing: content-box
        // JS will set a height when adding .__open for smooth animation
        transition: height 200ms ease

        li
            // padding-right: $nav-gap
            line-height: $nav-link-height
            height: $nav-link-height
            z-index: 0
            a, button
                // line-height: inherit
                height: inherit
                display: block
        // overflow expand button is added after the display list
        + button.__toggle
            position: absolute
            z-index: 2
            margin: 0
            top: 0
            bottom: 0
            right: 0 //$nav-gap
            button
                max-height: 100%

        // JS makes an invisible clone for 1:1 measuring.
        // If the last item is pushed to a new line, .__overflow is added to
        //  .__display
        &.__sizer
            opacity: 0
            height: 0
            // opacity: 0.5
            // height: 20px
            padding-bottom: 0
            overflow: hidden
            margin: 0
            flex-wrap: wrap
            justify-content: flex-start
            // Show controls

        // Javascript will add a button to expand
        + button.__toggle
            display: none
            // Override breadcrumbs
            padding: 0 !important
            // Swap text for icons
            font-size: 0 !important
            width: $nav-link-height
            height: 100%
            box-sizing: border-box
            background-position: center
            background-repeat: no-repeat
            background-color: transparent !important
            background-size: size(3) size(3)
            border: 1px solid transparent !important
            background-image: url('../img/overflow-open_white.svg')
            &:hover, &:focus-visible
                border-color: $col-line !important

        &.__overflow
            // Left align and allow cropping to right
            align-items: flex-start
            justify-content: flex-start
            flex-wrap: nowrap
            // Increase height and wrap contents
            &.__open
                height: auto
                flex-wrap: wrap

            &:not(.__open)
                // Show expand button
                + button.__toggle
                    display: block
                    background-color: #002855
                // Fade to blue overlay under toggle button when overflow active
                &:after
                    display: block
                    z-index: 1
                    pointer-events: none
                    content: ''
                    right: 0
                    top: 0
                    bottom: 0
                    width: $nav-link-height * 2
                    position: absolute
                    background: linear-gradient(to right, transparent, $col-blue 70%, $col-blue)

