
$col-blue: #003087
$col-gold: #DAAA00
$col-black: #000000
$col-white: #ffffff

$col-border: #6B6B6B
$col-panel: #F0F0F0

$col-link: #0e55b5
$col-link-visited: #773678
$col-text: #0A0A0A

$col-navy: #002855
$col-ocean: #005776
$col-powder: #9ADBE8
$col-steel: #8FACD9
$col-cerulean: #00A3E1
$col-topaz: #00B7BD
$col-teal: #00816D
$col-lime: #ADDC91
$col-yellow: #FDD26E
$col-tan: #A39382

// Utility color palette
$col-success: #35802e
$col-warning: #cdbb00
$col-error: #cb1a0b
$col-grey: #999999
$col-active: #4887da
$col-active-border: #4485dd
$col-focus: #4887da // with 4px blur radius

// text

$col-text-grey: #686868

$col-line: mix($col-border, $col-white)
$col-base-grey: color-shade($col-grey, 0)


// Seperate dictionaries for background, border and text
$cols-link-btn-primary-text: (normal: $col-white, focus: $col-white, active: $col-white)
$cols-link-btn-primary-base: (normal: $col-blue, focus:  $col-black, active: lighten($col-black, 20%))
$cols-link-btn-primary-border: (normal: $col-blue, focus: $col-black, active: $col-black)
// Combine layers into one variable for passing to mixins
$cols-link-btn-primary: (base: $cols-link-btn-primary-base, text: $cols-link-btn-primary-text, border: $cols-link-btn-primary-border)

$cols-link-btn-secondary-text: (normal: $col-blue, focus: $col-white, active: $col-white)
$cols-link-btn-secondary-base: (normal: $col-white, focus: $col-black, active: lighten($col-black, 20%))
$cols-link-btn-secondary-border: (normal: $col-black, focus: $col-black, active: $col-black)
$cols-link-btn-secondary: (base: $cols-link-btn-secondary-base, text: $cols-link-btn-secondary-text, border: $cols-link-btn-secondary-border)

$cols-link-btn-tertiary-text: (normal: $col-text, focus: $col-text, active: $col-text)
$cols-link-btn-tertiary-base: (normal: $col-white, focus: $col-white, active: darken($col-white, 20%))
$cols-link-btn-tertiary-border: (normal: $col-white, focus: $col-black, active: $col-black)
$cols-link-btn-tertiary: (base: $cols-link-btn-tertiary-base, text: $cols-link-btn-tertiary-text, border: $cols-link-btn-tertiary-border)


// inputs
$col-input-border: #cacaca
$col-input-border-focus: #54b0ff


// /* UNCOMFIRMED COLOURS */

$col-access-outline: #464646


// /* font families */

$type-sans: 'Plus Jakarta Sans', 'Century Gothic', sans-serif
$type-serif: 'Petrona', 'Palatino', serif

$type-root-size: 15px
$type-root-size-max: 17px
$type-root-height: 1.5
$type-root-weight: 400
$type-root-style: normal

$type-h1: (size-min: 24px, size-max: 43px, height: 1.2, weight: 700, margin-min: 24px, margin-max: 36px)
$type-h2: (size-min: 24px, size-max: 32px, height: 1.2, weight: 700, margin-min: 12px, margin-max: 24px)
$type-h3: (size-min: 21px, size-max: 24px, height: 1.3, weight: 600, margin-min: 12px, margin-max: 24px)
$type-h4: (size-min: 16px, size-max: 18px, height: 1.3, weight: 500, margin-min: 12px, margin-max: 24px)
$type-h5: (size-min: 14px, size-max: 16px, height: 1.3, weight: 500, margin-min: 12px, margin-max: 24px)
$type-h6: (size-min: 14px, size-max: 16px, height: 1.3, weight: 400, margin-min: 12px, margin-max: 24px, transform: uppercase)
$type-label: (size-min: 12px, size-max: 14px, height: 1.2, weight: 700, margin-min: 6px, margin-max: 12px, transform: uppercase)
$type-h1-display: (size-min: 24px, size-max: 43px, height: 1.3, weight: 600, margin-min: 24px, margin-max: 36px, font: $type-serif)
$type-h2-display: (size-min: 24px, size-max: 32px, height: 1.3, weight: 600, margin-min: 12px, margin-max: 24px, font: $type-serif)
$type-h3-display: (size-min: 21px, size-max: 24px, height: 1.4, weight: 500, margin-min: 12px, margin-max: 24px, font: $type-serif)


// /* Core Margins and spacing */

// size() is a multiple of this!
$size-root: 8px

// Official breakpoint values derrived from the design table.
$breakpoint-names: 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge'
$breakpoint-sizes: 0px, 576px, 768px, 992px, 1200px, 1440px
$breakpoint-grid-gutter: 16px, 16px, 16px, 16px, 24px, 24px
$breakpoint-page-margin: 16px, 16px, 24px, 24px, 24px, 96px

$page-margin-max: 96px
// ie11
$page-margin-fallback: 48px
$grid-gutter-small: 16px
$grid-gutter-large: 24px


// /* Page size and media query variables */

$page-gutter-size: 16px
$page-gutter-size-max: 24px
$content-max-width: 1200px
// largest $breakpoint-page-margin
$page-max-width: $content-max-width + $page-margin-max * 2

// min-max viewpoint range for standard fluid sizes
$fluid-max-vw: $content-max-width
$fluid-min-vw: 320px


// margin between paragraphs, lists, smaller headers
$margin-text: size(2)
// margin between list item
$margin-list: size(1)
// Margins for most blocks, larger headers headers, things more important than a paragraph
$margin-block: size(3)
$margin-block-half: size(1.5)
$accent-width: $margin-block
// space between minor sections, e.g. above h3
$margin-subsection: size(6)
// space between distinct sections, e.g. above h2
$margin-section: size(12)
// half the block margin so they stack and make a full
$pad-block: $margin-block * 0.5



// /* 12 column grid system */

$column-padding: $margin-block * 0.5
$column-core-total: 12

// /* breakpoints */



// Link Buttons
$link-button-width: 272px
$link-button-height-min: 47px
$link-button-height-max: 50px

// // Form inputs
// $input-height: 60px
// $input-elevation: 2
// $input-border-radius: 3px
// $input-border-width: 2px

// Cards (teaser and event)
$card-height-fluid-min: 150px
$card-height-fluid-max: 250px



$tab-height: 70px



$core-zindex-popup: 50
$core-zindex-navigation: 100
$core-zindex-sticky-header: 80
