



@mixin breakpoint($name)
    $_index: index($breakpoint-names, $name)
    @if not $_index
        // Assume a direct pixel value
        @media (min-width: $name)
            @content
    @else if $_index > 1
        @media (min-width: #{nth($breakpoint-sizes, $_index)})
            @content
    @else if $_index == 1
        // minimum always visible, default with no query
        @content


@mixin breakpoint-max($name)
    $_index: index($breakpoint-names, $name)
    @if not $_index
        // Assume a direct pixel value
        @media (max-width: $name - 0.01)
            @content
    @else if $_index < 6
        @media (max-width: #{nth($breakpoint-sizes, $_index + 1) - 0.01})
            @content
    @else if $_index == 6
        // maximum always visible, default with no query
        @content



// remove margin from first and last item so it sits flush in it's parent container.
@mixin child-margins-flush($force: false)
    @if $force
        > *:first-child
            margin-top: 0px !important
        > *:last-child
            margin-bottom: 0px !important
    @else
        > *:first-child
            margin-top: 0px
        > *:last-child
            margin-bottom: 0px

// Fluid values from minimum to maximum viewport widths.
@mixin fluid-property($properties, $min-value, $max-value, $min-vw: $fluid-min-vw, $max-vw: $fluid-max-vw)
    // Defaults are px unis. If you want to use em units you ALSO need to supply em for $min-vw and $max-vw. If 1rem = 16px that's 40rem and 80rem.
    // e.g. @include fluid-properties(font-size, 16px, 18px)
    // e.g. @include fluid-properties((padding-left, padding-right), $size1, $size2)
    // It outputs something like: calc(16px + 2 * ((100vw - 640px) / 640))
    // First two numbers are the minimum property size, and the diference between minimum and maximum property size.
    // Last two numbers are the minimum view width, and again, the diference between the minimum and maximum view width.
    // The media queries stop it shrinking or growing beyond the minimum and maximum.

    @each $property in $properties
        #{$property}: $max-value

    @media screen and (max-width: $max-vw)
        @each $property in $properties
            // magical calc statement provides the fluid value
            #{$property}: clamp($min-value, fluidcalc-unclamped($min-value, $max-value, $min-vw, $max-vw), $max-value)

// Shorthand for using CSS custom properties... with IE11 fallback
@mixin css-var($properties, $css-var-name, $ie_fallback)
    @each $property in $properties
        #{$property}: #{$ie_fallback}
        #{$property}: var(#{$css-var-name})


// Fluid values from minimum to maximum viewport widths.
@mixin fluid-property($properties, $min-value, $max-value, $min-vw: $fluid-min-vw, $max-vw: $fluid-max-vw)
    // Defaults are px unis. If you want to use em units you ALSO need to supply em for $min-vw and $max-vw. If 1rem = 16px that's 40rem and 80rem.
    // e.g. @include fluid-properties(font-size, 16px, 18px)
    // e.g. @include fluid-properties((padding-left, padding-right), $size1, $size2)
    // It outputs something like: calc(16px + 2 * ((100vw - 640px) / 640))
    // First two numbers are the minimum property size, and the diference between minimum and maximum property size.
    // Last two numbers are the minimum view width, and again, the diference between the minimum and maximum view width.
    // The media queries stop it shrinking or growing beyond the minimum and maximum.

    @each $property in $properties
        #{$property}: $max-value

    @media screen and (max-width: $max-vw)
        @each $property in $properties
            // magical calc statement provides the fluid value
            #{$property}: clamp($min-value, fluidcalc-unclamped($min-value, $max-value, $min-vw, $max-vw), $max-value)


// Outputs a fluid pixel font-size based on the screen width
@mixin fluid-font-size($min-px: null, $max-px: null)
    // e.g. fluid-font-size(16px, 18px)
    // If the designer hasn't provided both, but you still want fluid relative to the rem...
    // e.g. fluid-font-size($max-px: 22px)

    @if not $min-px
        // Minimum font size not supplied, calculate relative to base font size
        $min-px: $font-size / $font-size-max * $max-px

    @if not $max-px
        // Maximum font size not supplied, calculate relative to base font size
        $max-px: $font-size-max / $font-size * $min-px

    @include fluid-property(font-size, $min-px, $max-px)


// CSS Grid layouts

@mixin grid-parent($gap: $margin-block)
    display: grid
    grid-template-columns: repeat(12, 1fr)
    gap: $gap

@mixin grid-item($xsmall-cols, $small-cols: false, $medium-cols: false)
    // Column span width at mobile, tablet, desktop
    grid-column: span $xsmall-cols
    @if ($small-cols)
        @include breakpoint('medium')
            grid-column: span $small-cols
    @if ($medium-cols)
        @include breakpoint('large')
            grid-column: span $medium-cols




// /* Content block helpers */

// add width, fluid margins, padding and center for top level content blocks.
@mixin page-content-block()
    max-width: $content-max-width
    box-sizing: content-box
    // @include fluid-property((padding-left, padding-right), $page-gutter-size, $page-gutter-size-max)
    // IE11 fallbacks
    padding-left: $page-margin-fallback
    padding-right: $page-margin-fallback
    // Breakpoint driven CSS Property
    padding-left: var(--bmr-page-margin)
    padding-right: var(--bmr-page-margin)
    margin-left: auto
    margin-right: auto





@mixin reset()
    margin: 0
    padding: 0
    box-sizing: border-box
    vertical-align: baseline
    text-transform: none
    transition: none
    font-weight: inherit
    font-size: inherit
    font-style: inherit
    box-shadow: none
    line-height: inherit
