// Anything that revolves around text. Typography, link buttons

// Standard header styles from a variables dictionary
@mixin head($vars, $layout: 0)
    @include fluid-font-size(map-get($vars, size-min), map-get($vars, size-max))
    line-height: map-get($vars, height)
    font-weight: map-get($vars, weight)
    // color: map-get($vars, color)
    @if map-has-key($vars, transform)
        text-transform: map-get($vars, transform)
    @if map-has-key($vars, font)
        font-family: map-get($vars, font)
    // Margins are optional
    @if $layout == 1
        @include fluid-property(margin-bottom, map-get($vars, margin-min), map-get($vars, margin-max))
        @include fluid-property(margin-top, map-get($vars, margin-min) * 2, map-get($vars, margin-max) * 2)


// Link colour and underline control
@mixin link($normal: 1, $focus: 0, $col-normal: inherit, $col-hover: inherit, $col-visited: inherit)
    // $normal and $focus are effectively boolean as to wether to show the underline
    @if $normal == 0
        text-decoration: none
    @else
        text-decoration: underline
    &:link
        @if $normal == 0
            text-decoration: none
        @else
            text-decoration: underline
    &:hover,
    &:focus-visible
        @if $focus == 0
            text-decoration: none
        @else
            text-decoration: underline

    // Colours are only set if values are present - no need to set "inehrit"

    // override primary colour
    @if $col-normal != inherit
        color: $col-normal
        // &:link,
        // &:hover,
        // &:focus,
        //     color: $col-normal

    @if $col-hover != inherit
        &:hover
            color: $col-hover

    // override visited colour - usuially omitted
    @if $col-visited != inherit
        &:visited
            color: $col-visited
    // If we're overriding normal, usially visited will match. e.g. coloured buttons!
    @if $col-visited != inherit and $col-normal != inherit
        &:visited
            color: $col-normal


// The common base for a link button, no colours or effects.
@mixin link-button-base()
    // This is typically used with @include
    // focus-effect(), hover-effect() and button-colors()
    display: inline-flex
    align-items: center
    justify-content: center
    max-width: 100%
    min-width: 150px
    // width: $link-button-width
    white-space: nowrap
    @include fluid-property(height, $link-button-height-min, $link-button-height-max)
    // @include fluid-property(line-height, $link-button-height-min, $link-button-height-max)
    //
    padding-left: size(2)
    padding-right: size(2)
    // text is just a smidge too high
    padding-top: 2px
    //
    border-width: 2px
    border-style: solid
    border-color: transparent
    background: transparent
    // font is not center, adjust with line height
    // line-height: ($_height - 4px) * 0.9
    // font-size: 15px
    font-size: $type-root-size-max
    font-weight: 500
    font-family: $type-sans
    text-align: center


// Colours for a button, expects a dictionary with three nested dictionaries.
@mixin link-button-colors($colors: $cols-link-btn-primary)
    $_base: map-get($colors, base)
    $_text: map-get($colors, text)
    $_border: map-get($colors, border)
    text-decoration: none
    border-color: map-get($_border, normal) !important
    background-color: map-get($_base, normal)
    color: map-get($_text, normal)
    // &:visited
    //      color: map-get($_text, normal)
    &:focus,
    &:hover
        // No underline when coloured
        text-decoration: none
        background-color: map-get($_base, focus)
        @if map-has-key($_border, focus)
            border-color: map-get($_border, focus) !important
        @if map-has-key($_text, focus)
            color: map-get($_text, focus)
    &:active
        background-color: map-get($_base, active)
        @if map-has-key($_border, active)
            border-color: map-get($_border, active) !important
        @if map-has-key($_text, active)
            color: map-get($_text, active)

