/*
 * UWA Universal Navigation
 * For SiteCore SXA and Generic systems
 * Timothy Phillips, Digital Channel, September 2024
 */

// @import "https://static.weboffice.uwa.edu.au/visualid/fonts/ttnormspro/ttnormspro.css"
// @import "https://static.weboffice.uwa.edu.au/visualid/fonts/novelpro/novelpro.css"

@import url('https://static.weboffice.uwa.edu.au/visualid/fonts/petrona/petrona.css')
@import url('https://static.weboffice.uwa.edu.au/visualid/fonts/plus-jakarta-sans/plus-jakarta-sans.css')


// Support content is pulled from the old devcore codebase, so probably has some irrelevant junk

// These may be needed before variables
@import navigation/support/nav_support.sass
@import navigation/support/nav_vars.sass
@import navigation/support/nav_mixins.sass
@import navigation/support/nav_mix_objects.sass
@import navigation/support/nav_mix_type.sass


// print the css variables seperately, default scope is root:
// @include define-css-vars('.uwa-nav__reset')
@include define-css-vars()
//('[class*=uwa-header__row], [id^=uwa-header__row], .uwa-nav__menus, [id^=uwa-footer__row]')

.uwa__match-header-width
    @include page-content-block()
// Elements -- Default styles with NO classes

body
    margin: 0
    padding: 0
    min-height: 100vh

// Page -- Common page elements outside the content area.
// @import 3_components/c_page/pag_common.sass
@import navigation/nav_header-layout.sass
@import navigation/nav_header-items.sass
@import navigation/nav_header-menus.sass
@import navigation/nav_breadcrumbs.sass
// @import navigation/nav_sidenav-wrapper.sass
// @import navigation/nav_sidenav-content.sass
@import navigation/nav_footer.sass


/* Overrides for SiteCore Classic study profile tooltip */
#uwa-header__row-primary
    .profile-header-nav-notification
        width: 18px
        height: 18px
        top: -8px
        right: -16px
        left: auto
        line-height: 16px
        font-size: 14px
        font-weight: bold
        background: $col-yellow
        color: $col-black
        position: absolute
        padding: 0px

    .header-module-nav-item-tooltip
        left: auto
        right: 100%
        transform: translate(-10%,-50%)
        line-height: 1.2

    .header-module-nav-item-tooltip-content
        width: auto !important
        vertical-align: bottom
        color: $col-text

#uwa-nav__menu-mobile
    .header-module-nav-item
        text-shadow: none
        display: block
        position: relative
        float: none

    .profile-header-nav-notification
        display: none

    .header-module-nav-item-tooltip-content
        width: auto !important
        white-space: nowrap
        padding: size(0)
        background-color: $col-yellow
        border-radius: 3px


    .header-module-nav-item-tooltip
        visibility: visible
        opacity: 1
        right: 0
        left: auto
        top: 0
        transform: none
        float: right
        padding: size(0) 0 0 0
        color: $col-text
        font-weight: normal
        border: none !important
        background: transparent !important
        box-shadow: none !important

    .header-module-nav-item-tooltip:after
        display: none

body
    background: #ffffff
