$nav-color-bright: #00A3E1
$nav-color-dark: #002855
$nav-font-size: 15px

.uwa-nav__menus-background
    // Screen size, z-index will place it below header and menus
    position: fixed
    left: 0
    top: 0
    width: 100vw
    height: 100vh
    z-index: $core-zindex-navigation - 2
    display: none

    &.__open
        background-color: rgba(0,0,0,0.1)
        display: block
    &.__shade
        background-color: rgba(0,0,0,0.2)

.uwa-nav__menus
    display: none
    z-index: $core-zindex-navigation + 1
    position: relative
    &.__open
        display: block
        position: absolute
        overflow: hidden
        background: white
        box-shadow: 0 5px 5px rgba(0,0,0,0.1)
    &.__megamenu
        left: 0
        right: 0
        @include page-content-block()
        // box-sizing: border-box !important
        > div, > nav
            max-width: 100%
            // width: 100%
            padding-left: 0 !important
            padding-right: 0 !important
            @include breakpoint-max('small')
                padding-left: size(1) !important
                padding-right: size(1) !important

    &.__fit
        left: 0
        right: 0
        > div, > nav
            // width: 100%
            z-index: 1

    // @include breakpoint-max('small')
    //      &.__open
    //         box-sizing: border-box
    //         left: auto
    //         width: 400px
    //         max-width: 100%


    // Add icons
    button.__control
        z-index: 2
        background-repeat: no-repeat
        border: 0
        outline: 0
        background-color: transparent
        // Hide the text, but not from screen readers
        line-height: 100px
        // overflow: hidden
        font-size: 0px !important
        background-position: center
        background-size: size(3)
        width: size(5)
        height: size(5)
        position: absolute
        border: 1px solid transparent !important
        top: 0
        &:hover, &:focus
            border-color: $col-line !important
        &:after
            // hide chevron
            display: none
        &._close
            background-image: url('../img/close_grey.svg')
            display: none
            right: 0

    @include breakpoint('medium')
        // mobile has it's own menu/close button, so only show on desktop
        &.__megamenu button._close
            display: block


.uwa-nav__menu
    display: none
    // position: absolute
    z-index: $core-zindex-navigation + 1
    background-color: $col-white
    // font-size: $nav-font-size !important
    color: $col-text
    padding: size(3)
    max-width: 500px + size(10)
    min-width: 200px
    @include breakpoint-max('small')
        // Override page content block padding
        padding: size(3) !important

    > * > *
        max-width: 100%

    &.__open
        display: block


.uwa-nav__menu
    // Common styles
    a, a:link, button
        display: inline-block
        outline: none
        background: none
        text-decoration: none
    button
        display: block
    h2 a
        color: $col-text

    ._links, ._title, > ul
        a[href], button._menu
            position: relative
            color: $col-black
            &:before
                // Underline as a psuedo-object, can be animated and doesn't affect layout
                position: absolute
                background-color: $nav-color-bright
                content: ''
                left: 50%
                right: 50%
                // default size is for links
                bottom: size(0)
                height: 1px
                transition-property: left, right
                transition-duration: 150ms
                transition-timing-function: ease-out
            &:hover, &:focus-visible, &.__open
                &:before
                    left: 0
                    right: 0
    // ._title a[href]:before
    //     bottom: 0

    // For top level nav in mobile menu
    ._links ul button._menu
        font-size: $nav-font-size !important
        border: none !important
        // border-bottom: 2px solid transparent !important
        @include psuedo-object('after', size(2.5), size(0), 1)
            @include psuedo-content-img('../img/chevron-right_bright.svg')
        &:before
            bottom: -2px
            height: 2px !important

    // Promotional CTAs
    ._promo
        display: none
        margin-top: size(4)
        &:has(a)
            display: block
        ul
            width: 100%
        li
            margin-bottom: size(2)
        a
            @include link-button-base()
            @include link-button-colors($cols-link-btn-secondary)
            // width: 280px !important
            max-width: 100% !important
            min-width: 150px
            width: auto
            // height: 40px
            margin: 0

        li:first-child a
            @include link-button-colors($cols-link-btn-primary)

    // Title block also doubles as Tier 1 link
    ._title
        position: relative
        margin-bottom: 0
        font-size: $nav-font-size
        h2
            margin: 0
            line-height: 30px
            font-size: $nav-font-size + 2
            font-weight: 600
            margin-bottom: 0
            a, span
                font-weight: inherit
                color: inherit
                font-size: inherit !important

                // Make space for animated underline
                padding-bottom: size(1)
                &:not([href])
                    cursor: default

            a[href]
                // Make space for arrow
                padding-right: size(4)
                &:after
                    display: inline-block
                    content: ''
                    position: relative
                    width: size(4)
                    height: size(3)
                    margin-right: size(-4)
                    top: -2px
                    vertical-align: middle
                    background-image: url('../img/arrow-right_bright.svg')
                    background-position: center right
                    background-repeat: no-repeat
                    background-size: size(3)
                    left: 0
                    transition: left 250ms ease
                &:hover:after, &:focus:after
                    left: size(0)

// LINK LISTS
.uwa-nav__menu
    // Two column width if more than 8 list items.
    &:not(._megamenu):has(ul > :nth-child(8))
        ._links
            // Big enough for two columns and gap
            width: 500px + size(3)
            max-width: 100%

    ul
        column-width: 250px
        gap: size(3)
        margin: 0
        a, strong, button:not(.__control)
            // display: block
            // font-weight: 500
            display: inline-block
            line-height: 24px
            font-size: $nav-font-size
            text-align: left
            padding-bottom: size(1)
            padding-top: size(1)
            // margin-bottom: 8px
            @media (hover:hover)
                // Tighten padding for mouse
                padding-top: size(0)
                padding-bottom: size(0)

        button.__accordion
            display: none
        li
            break-inside: avoid-column
            position: relative
            &:last-child
                margin: 0
        // 2nd tier
        ul
            display: none

    // Main Hamburger menu cloned for mobile
    &.__mobile-root
        min-width: 300px
        max-width: 100%
        ul
            column-width: unset
        button, a
            font-weight: 600 !important
        a[href]:before
            // hover underline match menu
            bottom: 0
            height: 2px
        button
            width: 100%
        li:not(:last-child)
            margin-bottom: size(1)
        ul + ul
            margin-top: size(3)
            padding-top: size(2)
            border-top: 1px solid $col-blue
            &._promo
                margin-top: 0
                border-top: none

// Megamenu variation
.uwa-nav__menu._megamenu
    padding-top: size(4)
    padding-bottom: size(4)

    > *
        > *
            @include child-margins-flush()
            box-sizing: border-box
            width: 100%

    // 2 tiers of links
    ._links ul
        a, strong, button:not(.__control)
            font-weight: 600
        li
            padding: 0
            column-width: 100%
            margin-bottom: size(1.5)
            ul
                display: block
                a
                    font-weight: normal
                li
                    margin-bottom: 0

    // Title block. Animation/accent code is seperate
    ._title
        margin-bottom: size(2)
        p
            margin-top: $margin-text
        h2
            color: $col-black
            font-size: $nav-font-size + 10
            margin-bottom: size(2)

    ._links
        break-inside: avoid-column
        flex-grow: 1

    // override standard grid
    &._search > *
        display: block !important
        > *
            padding-right: 0 !important

    #uwa-nav__search-suggestions
        margin-top: $margin-block
        // Hide (including title) unless links present
        li
            display: none
        li:has(a)
            display: block

        // Autocomplete first on mobile
        @include breakpoint-max('small')
            display: flex
            flex-flow: column nowrap
            gap: 0
            li
                order: 1
            ._results
                order: 0


    // Largest is title and promo on left
    @include breakpoint('large')
        > *
            display: grid
            // Big enough for "The University of Western Australia -->" on two lines
            grid-template-columns: 280px auto
        ._title, ._promo
            grid-column: 1
            padding-right: size(1)
            li:not(:last-child)
                margin-bottom: size(2)
        ._promo
            grid-row: 2
            // Make the two buttons equal width
            ul
                column-width: auto
                display: inline-block
                width: auto
                a
                    width: 100%
        ._links
            grid-column: 2
            grid-row: 1 / 4

    // Full mobile
    @include breakpoint-max('small')
        ._title
            p
                display: none
        ._title, ._promo
            li:not(:last-child)
                margin-bottom: size(2)

        // Accordions only on small mobile
        ul
            column-width: auto
            > li
                margin-bottom: size(1)
            a, strong
                padding-top: 8px
                // margin-bottom: 8px

            button.__accordion
                display: block
                border: 1px solid transparent !important
                background-image: url('../img/chevron-down_bright.svg')
                right: 0
                &:before
                    // dividing line to indicate arrow is separte from link
                    display: block
                    position: absolute
                    content: ''
                    width: 1px
                    background-color: $col-line
                    right: size(6)
                    top: -1px
                    height: size(5)
                &:hover, &:focus-visible
                    border-color: $nav-color-bright !important
                + ul
                    position: relative
                    height: 0
                    overflow: hidden
                    box-sizing: border-box
                    transition: height 250ms ease

                &.__open
                    background-image: url('../img/chevron-up_bright.svg')
                    + ul
                        margin-bottom: size(2)
                        border-bottom: 1px solid $col-line
                        li:last-child a
                            // margin on the link, so JS will measure the height right
                            margin-bottom: size(2)



// Search form
.uwa-nav__menu._search
    ._close
        // Not needed as there's a clear X in the search button
        display: none !important
    ._form
        flex-basis: 100%
        form
            width: 100%
            display: flex
            flex-flow: row nowrap
            gap: size(2)
            //SXA OLD
            background-color: none
            padding: 0px

            > *
                flex-grow: 1
                flex-shrink: 1

            &:before
                content: ''
                width: $link-button-height-max
                height: $link-button-height-max
                background: url('../img/search_grey.svg') center no-repeat
                background-size: size(3)
                z-index: 2
                margin-right: -$link-button-height-max - size(2)

            input, button
                line-height: 40px
                min-width: $link-button-height-max
                padding-left: size(2)
                padding-right: size(2)
                border: 1px solid transparent
                line-height: $link-button-height-max - 2px
                font-size: $nav-font-size + 2
                // font-weight: 500
                height: $link-button-height-max
                box-sizing: border-box
                font-weight: normal


            button[type=submit]
                text-align: center
                @include link-button-colors($cols-link-btn-primary)


            input[type=text]
                z-index: 1
                flex-basis: 70%
                padding-right: $link-button-height-max
                padding-left: $link-button-height-max
                margin-right: - $link-button-height-max - size(2)
                border-color: $nav-color-bright
                &:focus, &:hover
                    border-color: $nav-color-dark
                    outline: none
                    box-shadow: none
                &::placeholder
                    color: $col-text-grey

            button[type=reset]
                z-index: 2
                flex-grow: 0
                // margin-left: size(-6)
                padding-left: $link-button-height-max - 2
                padding-right: 0
                line-height: 150px
                overflow: hidden
                width: $link-button-height-max
                height: $link-button-height-max
                min-width: $link-button-height-max
                background: url('../img/close.svg') center no-repeat
                background-size: size(3)
                border: 1px solid transparent !important
                &:focus, &:hover
                    border-color: $col-blue !important

            @include breakpoint-max('small')
                // flex-wrap: wrap
                button[type=submit]
                    width: 80px
                    padding: 0
            @include breakpoint-max(380px)
                // Allow search to be pushed onto next line
                flex-wrap: wrap


// Accent and animations
@include breakpoint('medium')
    .uwa-nav__menu
        ._title
            position: relative
            h2 a
                // padding: size(0) 0
                color: $col-white
                position: relative
        &.__animate ._title
            h2 a
                color: inherit
                animation: uwa-nav_title 400ms linear
    .uwa-nav__menu._megamenu
        ._title, ._promo, ._links, ._form
            padding-left: size(3)
        ._title
            padding-top: size(2)

            &:before
                display: block
                position: absolute
                top: 0
                left: 0
                width: 150px
                height: 50px
                content: ''
                background-color: $col-blue
                clip-path: polygon(0% 100%, size(1) 100%, size(1) 100%, size(1) 100%, size(1) 100%, 0 100%)

        &.__animate ._title
            // Slide in the text and draw the accent, needs to be applied after .__open
            h2 a
                // color: $col-text
                animation: uwa-nav_accent-title 400ms linear
            &:before
                animation: uwa-nav_accent 400ms linear
                clip-path: polygon(0% 0%, 100% 0%, 100% size(1), size(1) size(1), size(1) 100%, 0 100%)


@keyframes uwa-nav_title
    // Slide in the title
    0%
        // color: $col-white
        opacity: 0
        top: size(-1)
    60%
        // color: $col-blue
        opacity: 1
        top: 0

@keyframes uwa-nav_accent-title
    // Slide in the title
    0%, 40%
        // color: $col-white
        opacity: 0
        left: size(-2)
    100%
        // color: $col-text
        opacity: 1
        left: 0

@keyframes uwa-nav_accent
    // Draw the accent from bottom to top, then right
    $_w: size(1)
    0%, 20%
        clip-path: polygon(0% 100%, $_w 100%, $_w 100%, $_w 100%, $_w 100%, 0 100%)
    40%
        clip-path: polygon(0% 0%, $_w 0%, $_w $_w, $_w $_w, $_w 100%, 0 100%)
    100%
        clip-path: polygon(0% 0%, 100% 0%, 100% $_w, $_w $_w, $_w 100%, 0 100%)

@include breakpoint-max('small')

    .uwa-nav__menu
        position: relative
        &.__animate
            animation: uwa-nav_slide-right 100ms linear
            left: 0

        &.__mobile-root, &._search
            &.__animate
                animation: uwa-nav_slide-left 100ms linear

@keyframes uwa-nav_slide-right
    0%
        opacity: 0
        left: size(2)
    100%
        opacity: 1
        left: 0

@keyframes uwa-nav_slide-left
    0%
        opacity: 0
        left: size(-2)
    100%
        opacity: 1
        left: 0
