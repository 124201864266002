$nav-font-size: 15px


#uwa-header__row-tertiary ol,
.uwa-nav__breadcrumbs ol
    // .component.breadcrumb nav ol
    display: flex
    flex-flow: row wrap
    align-items: flex-start
    align-content: flex-start
    list-style: none
    margin: 0
    // padding: size(1) 0
    width: 100%
    // overflow-x: hidden
    font-size: $nav-font-size - 3
    color: $col-text-grey
    line-height: 18px
    height: $nav-link-height
    overflow: hidden
    position: relative
    transition: height 200ms ease
    position: relative
    li, a
        font-size: $nav-font-size - 3
        color: $col-text-grey !important
        font-family: $type-sans
        vertical-align: middle
        // The font sits a bit low, so -3px to move it up
        line-height: $nav-link-height - size(1) - 3
    a
        min-height: $nav-link-height
        // line-height: inherit
        // Subtract the borders.. but we also want a little lower
        padding-top: size(0)
        padding-bottom: size(0)
        display: inline-block
        outline: none
        box-sizing: border-box
        border: 1px solid transparent
        font-weight: 500 !important
        @include link(0, 1)

    li:last-child a
        // cursor: default !important
        // text-decoration: none !important
        font-weight: 400 !important
        // font-style: italic

    img
        vertical-align: middle
    li
        &:not(:first-child):not(._close)
            @include icon-background('../img/breadcrumb-chevron_grey.svg', size(2), size(0), $side: left)

        &:not(:last-child)
            padding-right: size(0)

    // Open state
    &.__open
        padding-right: $nav-link-height
        li._close
            display: block
    &:not(.__open)
        // Truncated version by default
        flex-wrap: nowrap
        max-width: 100%
        li._close
            display: none
        li, a
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
        li
            &:first-child
                // don't truncate UWA
                flex-shrink: 0
            &:last-child
                // Ensure the current page gets truncated harder
                flex-basis: 100px
                flex-grow: 1
                flex-shrink: 1
            a, button
                line-height: inherit
                height: inherit
                max-width: 100%
                // display: block
        @include breakpoint('medium')
            // UWA > Site > *** > Item > Page
            &:has(:nth-child(5))
                li
                    display: none
                li:nth-child(3)
                    display: block
                    flex-shrink: 0
                    a
                        // Make item appear as bullet. JS will make this expand!
                        --nav-expander: true
                        @include icon-background('../img/breadcrumb-open_grey.svg', size(3))
                        font-size: 0
                        width: $nav-link-height
                        &:hover, &:focus
                            border: 1px solid $col-line

                li:nth-last-child(-n+2)
                    display: block
                li:nth-child(-n+2)
                    display: block

        @include breakpoint('large')
            // UWA > Site > *** > Item > Item > Page
            li:nth-last-child(3)
                display: block
        @include breakpoint-max('small')
            // *** > Item > Item > Page
            &:has(:nth-child(4))
                li
                    display: none
                li:nth-last-child(3)
                    display: block
                    background-image: none
                    padding-left: 0
                    flex-shrink: 0
                    a
                        // Make item appear as bullet. JS will make this expand!
                        @include icon-background('../img/breadcrumb-open_grey.svg', size(3))
                        --nav-expander: true
                        font-size: 0
                        width: $nav-link-height
                        &:hover, &:focus
                            border: 1px solid $col-line

                li:nth-last-child(-n+2)
                    display: block


// SiteCore overrides
#uwa-header__row-tertiary,
.uwa-nav__breadcrumbs
    .breadcrumb, .cell
        // width: initial !important
        flex-shrink: 1 !important
    ol

        .separator
            display: none
        div:before
            display: none
        div
            padding: 0px
    // SXA OLD
    .breadcrumb-item + .breadcrumb-item::before
        display: none

// .component.breadcrumb
//     flex-shrink: 1 !important
//     ol
//         .separator
//             display: none
//         div:before
//             display: none !important
//         div
//             padding: 0px !important
//         // .home:before
//         //     display: none !important

