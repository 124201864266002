
// gold bar above special headers in core brand
@mixin overline($padding: size(2), $width: 70px)
    padding-top: $padding - 4px
    &:before
        @include fluid-property('height', 2px, 4px)
        @include fluid-property('margin-bottom', size(2) - 2, size(3) - 4)
        background-color: $col-gold
        width: $width
        top: 0
        left: 0
        content: ''
        display: block




@mixin psuedo-object($position, $size-px, $margin: size(2), $vertical-align: 0)
    position: relative
    @if $position == 'before'
        padding-left: $size-px + $margin
    @else
        padding-right: $size-px + $margin
    &:#{$position}
        display: block
        position: absolute
        top: 0
        @if $position == 'before'
            left: 0
        @else
            right: 0
        content: ''
        width: $size-px
        height: $size-px
        @if $vertical-align != 0
            top: 50%
            margin-top: $size-px * -0.5
        @content


@mixin psuedo-content-img($url)
    background-image: url($url)
    background-position: center
    background-repeat: no-repeat
    background-size: contain




// Placeholder for a psuedo element image on the left.
// TODO: Refactor for before/left and after/right
@mixin psuedo-before-img-base()
    position: relative
    &:before
        display: block
        position: absolute
        top: 0
        left: 0
        content: ''
        background-position: center
        background-size: contain
        background-repeat: no-repeat
        @content

// fixed size image on the left
// TODO: Refactor for before/left and after/right
@mixin psuedo-before-img($url, $size-px, $margin: size(2), $vertical-align: 0)
    @include psuedo-before-img-base()
    padding-left: $size-px + $margin
    &:before
        width: $size-px
        height: $size-px
        @if $url
            background-image: url($url)
        @if $vertical-align != 0
            top: 50%
            margin-top: $size-px * -0.5
        @content



// background-image based icon - WAAAAY less code, but only one per object
@mixin icon-background($url, $size: size(3), $margin: size(1), $side: false)
    // $side: top, bottom, left, right or false for centred .
    background-image: url($url)
    background-repeat: no-repeat
    background-size: $size
    @if ($side == false)
        background-position: center
    @else
        padding-#{$side}: $size + $margin
        background-position: #{$side} center
